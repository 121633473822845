@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;700&display=swap');


* {
  /* transition: all 0.3s ease; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Reem Kufi', sans-serif;
  /* font-size: 1vw;  */

}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 1vh/1vw;
  margin-bottom: 0;
  margin-top: 0;
}

h2 {
  /* color: #8DADFF; */
  color: #FFB6CC;
}

ul {
  margin: 0px;
  padding: 0px;
}

.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* background: linear-gradient(45deg, #8DADFF, #B8CCFF); Adjusted gradient background */
  color: #0013C3;
  height: fit-content;
  z-index: 1;
}

.header-title {
  margin-left: 2vw;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Montserrat Alternates', sans-serif;
  color: #e0e3f9;
}

.header-title p {
  margin: 0;
  margin-bottom: 4px;
}

.header-subtitle {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Reem Kufi', sans-serif;
}

.header-tagline {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Reem Kufi', sans-serif;
  margin-right: 2vw;
  margin-bottom: 0px;
}

.navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center the navigation buttons */
  width: 60vw;
  flex-grow: 1;
  align-self: center;
  z-index: 1000;
  /* Adjust the value as needed */
}

.navigation-button {
  text-decoration: none;
  color: #6C63FF;
  font-size: 24px;
  display: inline-block;
  text-align: center;
  flex-grow: 1;
  border-width: 4px;
  border-style: solid;
  border-radius: 4px;
  margin-left: 2vw;
  padding: 1vw;
  background-color: transparent;
  align-items: center;
}

/* Add these styles for the hover effect on the navigation button on all pages */
.navigation-button:hover {
  background-color: #6C63FF;
  color: #fff;
}

.navigation-button.active {
  background-color: #6C63FF;
  color: #fff;
}

.splash-container,
.about-container,
.experience-container,
.projects-container,
.contact-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* background-color: #191970; */
  max-width: 100%;
  min-width: 768px;
  min-height: 100vh;
}

.main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: radial-gradient(circle, rgb(74, 62, 249) 0%, rgba(79, 255, 182, 0.556) 100%),
    url(https://grainy-gradients.vercel.app/noise.svg);
  filter: saturate(105%) contrast(110%) brightness(1);
  /* animation: shiftNoise 10s linear infinite; Adjust the duration and timing function as needed */
}

.splash-main-container {
  min-height: 100%;
  display: flex;
  flex: 1;
  background: rgb(34, 193, 195);
  background: radial-gradient(circle, rgb(74, 62, 249) 0%, rgba(79, 255, 182, 0.556) 100%),
    url(https://grainy-gradients.vercel.app/noise.svg);
  filter: saturate(105%) contrast(110%) brightness(1);
  /* animation: shiftNoise 10s linear infinite; Adjust the duration and timing function as needed */
}

.section-icon{
  width: 1em;
  height: 1em;
  padding-right: 10px;
}

.navigation-button .section-icon{
  width: 1em;
  height: 1em;
  padding-right: 10px;
  filter: brightness(0) saturate(100%) invert(38%) sepia(38%) saturate(4844%) hue-rotate(229deg) brightness(101%) contrast(101%);
}

.navigation-button:hover .section-icon{
  filter: brightness(100%) saturate(0%);
}

.navigation-button.active .section-icon{
  filter: brightness(100%) saturate(0%);
}


.main-content {
  flex: 1;
  padding: 0px;
  /* Add padding or adjust as needed */
  animation: fadeInAnimation ease 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.bio-panel,
.education-panel,
.image-panel {
  padding: 40px;
  /*Add padding or adjust as needed*/
  margin: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: 1.0vw; */
  word-wrap: break-word;
  overflow: visible;
  /* flex: 1; */
  line-height: 1.5;
  animation: fadeInAnimation ease 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  max-width: 768px;
}

.bio-panel {
  /* width: 60%; */
  padding: 40px;
  /* Add padding or adjust as needed */
  margin: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: 1.2vw; */
  /* Adjust the initial font size */
  word-wrap: break-word;
  overflow: visible;
  flex: 1;
  line-height: 1.5;
  max-height: 100%;
  /* Ensure the panel takes up the full height */
}

.image-panel {
  /* max-width: 40%; */
  overflow: hidden;

}

.image-panel img {
  width: 100%;
  height: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.about-right-col {
  max-width: 40%;
  max-height: 100%;
  flex: 1;
}

.main-experience-container {
  /* display: flex; */
  /* flex-direction: row; */
  gap: 20px;
}

.experience-column {
  padding: 10px;
  /* Add padding or adjust as needed */
  margin: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  word-wrap: break-word;
  /* overflow: auto; */
  flex: 1;
  /* max-width: none; Remove the max-width property to allow columns to expand */
  line-height: 1.3;
  animation: fadeInAnimation ease 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  max-width: 768px;
}

.job-title{
  margin: 0;
  padding: 0;
  color: #FFB6CC;
}

.company-name{
  margin: 0;
  padding: 0;
}

.experience-right-column{
  display: flex;
}


.work-experience,
.skills-technologies
 {
  padding: 40px;
  margin: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  font-size: min(2vw, 16px);
  /* Use a minimum of 1.5vw or 18px for font size */
  word-wrap: break-word;
  /* overflow: auto; */
  /* max-width: none; */
  /* Remove the max-width property for each column */
  /* width: 100%; */
  /* Ensure each column takes up the available width */
  line-height: 1.5;
  /* max-width: 768px; */
}

.publications {
  max-width: 768;
  padding: 40px;
  margin: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: min(2vw, 22px); */
  /* Use a minimum of 1.5vw or 18px for font size */
  word-wrap: break-word;
  /* overflow: auto; */
  line-height: 1.5;
}

.skill.technology {
  background-color: rgb(88 44 149)

}

.skill.tag {
  background-color: #1c229c;

}

.experience-main {
  display: flex;
  flex: 1;
}

.custom-bullet-point {
  display: inline-block;
  min-width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  background-size: cover;
  /* or cover, depending on your preference */
  background-repeat: no-repeat;
  margin-right: 8px;
  /* Adjust the spacing between the bullet point and text as needed */
  /* Add any additional styling as needed */
  display: flex;
  
}

.list-item {
  /* color: #ffb6cc; */
  font-size: 1.3em;
  list-style-type: none;
  /* Hide the default list item marker */
  display: flex;
  /* Align items horizontally */
  align-items: center;
  /* Center items vertically */
  margin-bottom: 10px;
  /* Adjust the margin between list items as needed */
  
}

.skills-technologies .list-item {
  color: #FFB6CC;
}

.link-url {
  color: #6C63FF;
  /* Set the color for the hyperlink text */
  margin-right: 0px;
  /* Adjust the margin as needed */
  width: fit-content;
  text-decoration: underline;
  /* Add an underline to indicate it's a link */
  display: inline;

}

.link-url:hover {
  color: #1B1F7D;
  /* Change color on hover for better visibility */
}

a:link {
  color: #cbc8ff;

}

a:hover {
  color: #1B1F7D;
}

/* visited link */
a:visited {
  color: #cbc8ff;
}

a:visited:hover {
  color: #1B1F7D;
}


.header-container {
  position: fixed;
  background-color: #000000c2;
/* background: transparent; */
  backdrop-filter: blur(50px);
  width: 100%;
}

/* Add these styles for the header tagline and subtitle on the splash screen */
.splash-container .header-tagline,
.splash-container .header-subtitle {
  color: #e0e3f9;
  /* Set the color for the text on the splash screen */
}

/* Add these styles for the navigation button on the splash screen */
.splash-container .navigation-button,
.about-container .navigation-button,
.experience-container .navigation-button,
.projects-container .navigation-button,
.contact-container .navigation-button {
  /* background-color: transparent; */
  border-color: #6C63FF;
  /* color: #6C63FF; */
  
}

/* Add these styles for the hover effect on the navigation button on the splash screen */
/* .splash-container .navigation-button:hover,
.about-container .navigation-button:hover,
.experience-container .navigation-button:hover,
.projects-container .navigation-button:hover,
.contact-container .navigation-button:hover {
  background-color: #6C63FF;
  color: #fff;
} */

.projects-panel{
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  height: auto;
  width: fit-content;
  align-self: center;
  padding: 40px;
}

.projects-panel button{
  background-color: rgb(61 54 177);
}

.project {
  break-inside: avoid;
  /* Prevent projects from breaking across columns */
  margin-bottom: 20px;
  /* Adjust spacing between projects as needed */
  max-width: 600px;
  flex: 1;
  background-color: #6C63FF;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: 1.0vw; */
  word-wrap: break-word;
  line-height: 1.5;
  animation: fadeInAnimation ease 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  min-height: 0;
  height: auto;
  /* Allow the content to determine the height */
  position: static;
  /* break-after: avoid-column; */
  /* break-before: avoid-column; */

}

.project:hover{
  cursor: pointer;
}

.project:hover .expanded{
  cursor: auto;
}

.project.expanded{
  height: 100%;
  break-after: avoid-column;
  /* break-before: avoid-column; */

}

.projects-container .main-container {
  display: block;
  height: auto;
}

.main-container,
.splash-main-container {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  flex: 1;
  background: radial-gradient(circle, rgb(74, 62, 249) 0%, rgba(79, 255, 182, 0.556) 100%);
  /* background: radial-gradient(circle, rgb(249 144 62 / 49%) 0%, rgb(253 79 255 / 21%) 100%); */
  /* filter: saturate(120%) contrast(110%) brightness(1); */
  background-attachment: fixed; /* Add this line for a fixed background */
  height: auto;
}

.splash-main-container {
  min-height: 100vh;
  
}

.main-projects-container {
  gap: 20px;
  /* Adjust the gap as needed */
  padding: 20px;
  /* column-count: 1; */
  /* This allows for dynamic columns */
  column-width: 300px;
  column-gap: 20px;
  /* Adjust the gap between columns as needed */
  /* width: 100vw; */
  /* margin: 0 auto; */
  align-items: center;
  height: auto;
}

.main-projects-container.column-mode {
  column-count: 1;
}

.main-projects-container.grid-mode {
  column-count: 3;
}



.section-header {
  font-size: 1.5em;
  /* Set the font size to twice the original size */
  /* color: #8DADFF; Set the color for the section header */
  margin-bottom: 20px;
  /* Adjust the margin as needed */
  text-align: center;
}

/* Add a hover effect for section headers */
.section-header:hover {
  color: #6C63FF;
  /* Change color on hover for better visibility */
}

/* Add a hover effect for navigation buttons
.navigation-button:hover {
  background-color: #6C63FF;
  color: #fff;
} */

/* Add a hover effect for list items */
.list-item:hover {
  background-color: #6C63FF;
  color: #fff;
}

p:hover {
  background-color: #6C63FF;
  color: #fff;
}

h2:hover {
  background-color: #6C63FF;
  color: #fff;

}


.contact-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  animation: fadeInAnimation ease 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  /* font-size: 1.2vw; */
  font-size: 18px;

}

.contact-text {
  /* width: 40%; */
  padding: 20px;
  background-color: #17174b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: 1.2vw; */
  word-wrap: break-word;
  overflow: auto;
  margin: 20px;
}

.contact-form {
  /* width: 40%; */
  padding: 20px;
  background-color: #17174b4b;
  border-color: #6C63FF;
  border-style: solid;
  border-radius: 4px;
  border-width: 4px;
  color: #F4F4F4;
  /* font-size: 1.2vw; */
}

/* Add these styles for the form elements */
form {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Ensure the form takes up the full width of its container */
}

label {
  margin-bottom: 8px;
}

input,
textarea {
  width: 100%;
  /* Make all input boxes the same width */
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #6C63FF;
  border-radius: 4px;
  font-size: 1.2vw;
  font-family: 'Reem Kufi', sans-serif;
  background-color: rgba(255, 255, 255, 0.689);
}

button {
  /* width: 100%;  */
  padding: 8px;
  background-color: #6C63FF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 1vw;
  margin: 4px;
}

/* Add styles for button hover effect */
button:hover {
  background-color: #1B1F7D;
}

.filter-buttons{
  color: white;
  font-size: 1vw;
 
}

.filter-buttons button{
  background-color: #433da8;
}

.filter-buttons button:hover{
  background-color: #6C63FF;
}

.filter-buttons button.active{
  background-color: #0013C3;
}

/* Add this to your existing styles or create a new section for it */
.contact-icons {
  display: flex;
  margin: 10px;
  /* Adjust the margin as needed */
  justify-content: center;
}

.contact-icon {
  width: 2vw;
  /* Adjust the width as needed */
  height: 2vw;
  /* Adjust the height as needed */
  margin: 10px;
  /* Adjust the spacing between icons as needed */
  cursor: pointer;
}

/* Add styles for the tooltips */
.contact-icon:hover::after {
  content: attr(title);
  display: inline-block;
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: -30px;
  /* Adjust the distance from the icon as needed */
  margin-left: 10px;
  /* Adjust the distance from the icon as needed */
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
}

.skill-group {
  margin-bottom: 10px;
}

.skill.github{
  background-color: #005a71;
}

.skill {
  position: relative;
  display: inline-block;
  background-color: #534cd6;
  /* border: 4px solid #534cd6; */
  border-radius: 5px;
  padding: 2px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  overflow: hidden;
  /* font-size: 1vw; */
}

.skill:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #534cd6, #6C63FF);
  background-size: 200% 200%;
  animation: gradientAnimation 1s infinite alternate;
  border-radius: 5px;
  z-index: -1;
  /* Move the gradient behind the text */
}

.skill:hover {
  position: relative;
  /* Set the position to relative for z-index to work */
  z-index: 1;
  /* Ensure the text is above the gradient */
}

.work-xp-tooltip{
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;

}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}

canvas {
  padding: 0;
  margin: 0;
  max-width: 100%;
  display: block;
}

iframe{
  width: 100%;
}

.caption {
  /* background-color: #33333362; */
  backdrop-filter: blur(10px);
  padding-left: 10px;
  text-align: center;
}

.hoverable:hover {
  filter: grayscale(0%) blur(0px) saturate(100%);

}

.details .images img,
.details .videos video {
  max-width: 100%;
  height: auto;
}

.details {
  /* max-width: 100%;
  width: 100%; */
  overflow: auto;
  /* Allow content to overflow if it exceeds the height of the div */
  height: auto;
  padding: 20px;
  background-color: #000;
}

.content {
  margin-bottom: 20px;
}

.images,
.videos {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: auto;
}

.details .images img {
  object-fit: contain;
}

@media (max-width: 768px) {
  .main-projects-container {
    grid-template-columns: 1fr;
    /* Adjust as needed */
  }

  .splash-container,
  .about-container,
  .experience-container,
  .contact-container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .projects-container {
    max-width: 100vw;
    min-width: 0;
    flex-direction: unset;
  }

  .main-content {
    width: 100%;
  }

  .main-container {
    width: 100vw;
    flex-direction: column;
  }



  .experience-column.skills-technologies {
    font-size: min(2vw, 18px);
    /* Adjust the font size for smaller screens */
  }

  .details {
    width: 90%;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .header-title {
    margin-left: 0;
    text-align: center;
  }

  .navigation-container {
    width: 100%;
    justify-content: space-around;
  }

  body {
    font-size: 4vw;
  }

  .header-title,
  .header-subtitle,
  .header-tagline,
  .list-item,
  p,
  h2,
  .contact-text,
  .contact-form,
  button,
  .skill {
    font-size: 5vw;

  }

  .section-header {
    font-size: 6vw;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .about-right-col {
    max-width: 100%;
  }

  .experience-column {
    max-height: none;
    /* Remove the max-height property */
    height: auto;
    /* Allow the div to grow based on its content */
    padding-left: 40px;
  }

  .navigation-button {
    font-size: 16px;
    /* Adjust as needed */
    padding: 1px;
    margin: 1px;
  }

  .navigation-button.active {
    background-color: #6C63FF;
    color: #fff;
  }

  canvas {
    width: 100vw;
    max-width: 100vw;

  }

  .splash-main-container {
    max-width: 100%;
    width: 100%;
  }

  .splash-container {
    width: 100%;
  }

  .project {
    flex: 1;
    /* padding: 20px; */
    background-color: #17174b4b;
    border-color: #6C63FF;
    border-style: solid;
    border-radius: 4px;
    border-width: 4px;
    color: #F4F4F4;
    font-size: 16px;
    word-wrap: break-word;
    overflow: auto;
    line-height: 1.5;
    /* animation: fadeInAnimation ease 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
    
  }

  

  .project.expanded{

  }

  .experience-right-column{
    display: block;
  }
  
  
  .work-experience,
  .skills-technologies
   {
    padding: 40px;
    margin: 20px;
    background-color: #17174b4b;
    border-color: #6C63FF;
    border-style: solid;
    border-radius: 4px;
    border-width: 4px;
    color: #F4F4F4;
    font-size: min(2vw, 16px);
    /* Use a minimum of 1.5vw or 18px for font size */
    word-wrap: break-word;
    /* overflow: auto; */
    /* max-width: none; */
    /* Remove the max-width property for each column */
    /* width: 100%; */
    /* Ensure each column takes up the available width */
    line-height: 1.5;
    /* max-width: 768px; */
  }
}

#splash-canvas {
  width: 100vw;
}

img.project-image {
  /* height: 100%; */
  width: 100%;
  height: auto;
  display: block;
}